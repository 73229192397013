@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.listingCards {
  padding: 0 24px 0px 24px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px 23px; /* row-gap column gap */

  @media (--viewportLarge) {
    padding: 0 36px 0px 36px;
  }
}

.listingCategory {
  width: 100%;
  position: relative;
}

.listingCategory h1 > div svg{
  position: relative;
  bottom: 2px;
} 

.listingCategoryLink {
  font-size: 24px;
  @media (--viewportMedium) {
    font-size: 28px;
  }
}

.listingCategoryLink:hover {
  cursor: pointer;
  color: var(--marketplaceColor);
}
.chevronIcon {
  fill: none;
  float: right;
  margin: 10px auto;
  @media (--viewportLarge) {
    display: none;
  }
}

.categoryCard {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.discoverMore {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceH5FontStyles;
  border: 1px solid var(--matterColorAnti);

  /* display: inline-block; */
  width: auto;
  min-height: auto;
  height: 41px;
  line-height: 41px;
  /* float: right; */
  flex-shrink: 0;
  margin-top: 2px;
  padding: 0px 16px;

  @media (--viewportMedium) {
    margin: auto 0 0 auto;
  }
}
.discoverMore:hover {
  border: 1px solid var(--marketplaceColor);
  color: var(--marketplaceColor);
}

.discoverMoreBtnDesktop {
  display: none;
  @media (--viewportLarge) {
    /* margin: auto; */
    margin-right: 24px;
    float: right;
    display: inline-block;
  }
}

.discoverMoreBtnMobile {
  margin: 0 auto 32px auto;
  display: block;

  @media (--viewportMedium) {
    display: none;
  }
}

.listingCard {
  
}

.mapClosed {
  @media (--viewportMedium) {
    flex-basis: calc(50% - 12px);
    max-width: calc(50% - 12px);
    margin-right: 24px;
    &:nth-of-type(even) {
      margin-right: 0px;
    }
  }
  @media (--viewportLarge) {
    flex-basis: calc(25% - 24px);
    max-width: calc(25% - 24px);
    &:nth-of-type(even) {
      margin-right: 24px;
    }
  }
  @media (--viewportXLarge) {
    flex-basis: calc(25% - 24px);
    max-width: calc(25% - 24px);
    &:nth-of-type(even) {
      margin-right: 24px;
    }
  }
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

.listingCard:nth-of-type(2n) {
  @media screen and (min-width: 1024px) and (max-width: 1920px) {
    margin-right: 0;
  }
}

.listingCard:nth-of-type(3n) {
  @media (--viewportXLarge) {
    margin-right: 0;
    gap:0;
  }
} 

.secondElement:nth-of-type(2n) {
  margin-right: 0;
  gap: 0;
}

.thirdElement:nth-of-type(3n) {
  margin-right: 0;
  gap: 0;
}

.thourthElement:nth-of-type(4n) {
  margin-right: 0;
  gap: 0;
}


.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: var(--matterColorLight);
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}
.christmasTopCornerBanner {
  position: absolute;
  height: 130px;
  left: -6%;
  z-index: 1;
  top: -15px;

  @media (--viewportMedium) {
    height: 150px;
    left: -2%;
    z-index: 1;
    top: -15px;
  }
  @media (--viewportLarge) {
   
  }
}

.christmasBottomCornerBanner {
  position: absolute;
  height: 130px;
  right: 0;
  z-index: 1;
  bottom: -40px;

  @media (--viewportMedium) {
    height: 150px;
    right: -2%;
    bottom: -15px;
  }

  @media (--viewportLarge) {
    
  }
}

.searchResultsPanelTitle {
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
  
}

.recombeeWrapper {
  display: grid;
  padding: 0 24px;
  width: 100%;
  /* height: 100%; causes the margin issue in Safari browsers */
  z-index: 1;
  @media (--viewportLarge) {
    padding: 0 36px;
  }
}