@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  display: flex;
  width: 100%;
  position: relative;
  min-width: 300px;
  /* Layout */
  /* display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  } 
  */
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
  /* position: relative; */
}

.discountTag {
  @apply --marketplaceH3FontStyles;
  float: left;
  position: absolute;
  left: 16px;
  top: 6px;
  /* z-index: 1000; */
  background-color: #de712b;
  padding-left: 13px;
  padding-top: 6px;
  padding-right: 13px;
  padding-bottom: 6px;
  color: #ffffff;
  border-radius: 20px;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
}
.ratingValue {
  float: right;
  padding-right: 3px;
}
.ratingView {
  @apply --marketplaceH3FontStyles;
  float: right;
  position: absolute;
  right: 16px;
  top: 1px;
  /* z-index: 1000; */
  color: #ffdf00;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
}

.ratingStar {
  padding-right: 3px;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.4));
}

.info {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 16px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.originalPriceValue {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  text-decoration: line-through;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.certificateInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.cancellationInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-left: 6px;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-left: 6px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.durationInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-left: 6px;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-left: 6px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.christmasfooterWrapper{
  display: flex;
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 30%;
}

.christmasfooterImg {
  bottom: 0;
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 0px 0px 10px 10px;
}

.christmasTopCornerBanner {
  position: absolute;
  width: 100%;
}

.christmaslights {
  position:absolute;
  width: 100%;
}