@import '../../styles/propertySets.css';

.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--matterColorBright);
}

.filtersWrapper {
  margin-top: 8px;
}

.footer {
  display: flex;
  margin-top: 8px;
}
.header {
  display: flex;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--matterColorNegative);
}
.resetAllButton {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH4FontStyles;
  color: var(--matterColorLabel);

  /* Layout */
  margin: 8px auto 0 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.cancelButton {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH4FontStyles;
  color: var(--matterColorLabel);

  /* Layout */
  margin: auto 24px 0 24px;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.applyButton {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);

  /* Layout */
  margin: auto 8px 0 24px;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
  }
}
