@import '../../styles/propertySets.css';

.root {
  position: relative;
  padding-top: 24px;
  padding-bottom: 17px;
  border-bottom: 1px solid var(--matterColorNegative);
}

.filterLabel,
.filterLabelSelected {
  @apply --marketplaceH3FontStyles;

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;
  padding: 4px 0 2px 0;
}

.filterLabel {
  color: var(--matterColorDark);
}

.filterLabelSelected {
  color: var(--marketplaceColor);
}

.labelButton {
  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

.formWrapper {
  padding-left: 20px;
}

.clearButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColorAnti);
  cursor: pointer;
  box-shadow: var(--boxShadowClearButton);
  border-radius: 4px;
  padding: 4px 8px;

  /* Layout */
  display: inline;
  float: right;
  margin-top: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
    box-shadow: var(--boxShadowFilterButton);
  }
}
