@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
  background-color: var(--matterColorBright);
  position: relative;
}

.categoryButtons {
  /*padding: 0 24px 96px 24px;*/
  & > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 8px;
  }
  &:first-child {
    margin-left: 36px;
  }
}

/* .categoryButtons {
  display: grid;
  grid-template-columns: repeat(11, 1fr); 
  grid-template-columns: repeat(11, minmax(0, 1fr));
  grid-template-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 0px;
  overflow: scroll;
  grid-auto-flow: column;
  scroll-snap-type: x mandatory;
  width: 100%
} */

.categoryButton {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceH5FontStyles;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 85px;
  line-height: 14px;
  flex-shrink: 0;
  margin: 0px;
  padding: 8px 16px;
  font-weight: 700;
  justify-content: space-around;
  scroll-snap-align: start;
  border: none;

  @media (--viewportLarge) {
    width: 100%;
    font-size:small;
  }
}

.categoryButton > svg {
  bottom: 2px;
  position: relative;
}

.embla {
  overflow: hidden;
}

.embla__container {
  display: grid;
  grid-auto-flow: column;
  column-gap: 10px;
  /* grid-auto-columns: 9%; */
  position: relative;
}

.embla__slide {
  /* margin-right: 24px; */
  flex: 0 0 auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-width: 100px;
  max-width: 100%; /* Prevent from growing larger than viewport */

}
.embla__slide:last-child {
  margin-right: 0px;
  /* margin-left: 10px; */
}

.embla__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  fill: #1bcacd;
  padding: 0;
  left: 0.5rem;
}

.embla__button:disabled {
  cursor: default;
  opacity: 0.3;
}

.embla__button__svg {
  width: 1rem;
  height: 1rem;
}

.embla__button_prev {
  --webkit-tap-highlight-color: transparent;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"; */
  cursor: pointer;
  padding: 0px;
  appearance: none;
  font-size: 1rem;
  text-align: left;
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  min-width: 2rem;
  min-height: 2rem;
  border-radius: 2rem;
  pointer-events: all;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0.0625rem 0.1875rem, rgba(0, 0, 0, 0.05) 0px 1.25rem 1.5625rem -0.3125rem, rgba(0, 0, 0, 0.04) 0px 0.625rem 0.625rem -0.3125rem;
  opacity: 0.85;
  border: 0.0625rem solid rgb(222, 226, 230);
  transition: opacity 150ms ease 0s;
  position: absolute;
  top: 25%;
  left: -1rem;

  &:hover {
    opacity: 1;
  }
}

.embla__button_next {
  --webkit-tap-highlight-color: transparent;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"; */
  cursor: pointer;
  padding: 0px;
  appearance: none;
  font-size: 1rem;
  text-align: left;
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  min-width: 2rem;
  min-height: 2rem;
  border-radius: 2rem;
  pointer-events: all;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0.0625rem 0.1875rem, rgba(0, 0, 0, 0.05) 0px 1.25rem 1.5625rem -0.3125rem, rgba(0, 0, 0, 0.04) 0px 0.625rem 0.625rem -0.3125rem;
  opacity: 0.85;
  border: 0.0625rem solid rgb(222, 226, 230);
  transition: opacity 150ms ease 0s;
  position: absolute;
  top: 25%;
  right: -1rem;

  &:hover {
    opacity: 1;
  }
}

.emblaCarouselWrapper {
  display: grid;
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
}